@import "./src/assets/scss/prepends.scss";

.contact-page #app{
  @include for-sp(){
    width: 100%;
    height: 100%;
  }
}
.contact {
  @include for-sp(){
    width: 100%;
    height: 100%;
  }
  &__body {
    box-sizing: border-box;
    padding: calc(140/1920 * 100vw) 0; 
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include for-sp(){
      height: 100%;
      margin-left: calc(150/750 * 100vw);
      text-align: left;
      align-items: flex-start;
    }
  }
  &__mail {
    width: max-content;
    padding: calc(10/1920 * 100vw) calc(20/1920 * 100vw);
    font-size: calc(80/1920 * 100vw);
    @include font-en-ttl;
    color: $white;
    @include gradient-color;
    line-height: 1;
    @include for-sp(){
      font-size: calc(34/750 * 100vw);
    }
  }
  &__txt {
    margin-top: calc(70/1920 * 100vw);
    p {
      font-size: calc(25/1920 * 100vw);
      color: $white;
      text-align: center;
      @include for-sp(){
        text-align: left;
        font-size: calc(26/750 * 100vw);
      }
    }
  }
}

